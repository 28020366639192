import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import {
  withWindowContext,
  useGTMInit,
  CompanyProvider,
  LocaleProvider,
  UserProvider,
  AuthModalProvider
} from '@sailogy/shipwright'
import company from 'config/company.json'
import boatValues from 'config/values.json'
import languages from 'config/languages.json'
import * as locales from 'config/locales'
import currencies from 'config/currencies.json'
import pkg from 'package.json'
import { FetchError, I18nJsonData } from '@sailogy/types'

const AuthModal = dynamic<any>(
  () => import('@sailogy/containers/Auth/Modal'),
  { ssr: false }
)

export interface Props {
  i18n: I18nJsonData
  i18nPath: string
  token?: string
  error?: FetchError
}


const Context: React.FC<Props> = ({ token, children, i18n, i18nPath }) => {
  const { locale } = useRouter()
  useGTMInit()

  return (
    <CompanyProvider value={company} values={boatValues}>
      <AuthModalProvider>
        <LocaleProvider
          initialValue={locale || 'en'}
          initialDictionary={i18n}
          i18nPath={i18nPath}
          version={pkg.version}
          languages={languages}
          locales={locales}
          currencies={currencies}
          priorityCountries={['IT', 'DE', 'GB', 'FR', 'ES', 'RU']}
        >
          <UserProvider token={token}>
            <AuthModal
              facebookAppId={process.env.FACEBOOK_APP_ID}
              googleAppId={process.env.GOOGLE_CLIENT_ID}
            />
            {children}
          </UserProvider>
        </LocaleProvider>
      </AuthModalProvider>
    </CompanyProvider>
  )
}

export default withWindowContext(Context)


// HOC with Context
// export function withContext<P extends {}>(C: NextPage<any>) {
//   const HOC: NextPage<P & HOCProps> = ({ lang, ...props }) => (
//     <Context lang={lang}>
//       <C lang={lang} {...props as P} />
//     </Context>
//   )
//
//   HOC.getInitialProps = C.getInitialProps as any
//
//   return HOC
// }
